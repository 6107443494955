
    import { reactive, onMounted, onBeforeUnmount, ref } from "vue";
    import ReumTextBox from '@/components/ReumLib/UI/ReumTextBox'
    import axios from "@/plugin/vue-axios";
    import { useStore } from 'vuex'
    import { useToast } from 'vue-toastification';
    import { useRouter } from 'vue-router';

    export default {
        name: 'Login',
        components: {
            ReumTextBox
        },
        setup(props) {
            const bg = ref(null);
            const router = useRouter();
            const store = useStore();
            const toast  = useToast();
            const comp = reactive({
                path: "",
                uid : "",
                upw : "",
                marginTop: 0,
                is_loading: false,

                handleResize : () => {
                    comp.marginTop = Math.round((bg.value.clientHeight-350)/2);
                },
                login: () => {
                    let params = {uid:comp.uid, pwd:comp.upw}
                    axios.post("/api/common/login", params).then((res) => {
                        if(res.data.err == 0) {
                            localStorage.setItem("useridx", res.data.id);
                            store.state.id_member = res.data.id;
                            store.commit("setUserID", comp.uid);
                            store.commit("setMenu", 1);
                            router.push("/SampleCtrl/list");
                        } else {
                            
                            toast.error(res.data.err_msg);
                        }
                    }).catch(() => {
                        toast.error("연결에 오류가 있습니다.");
                    });

                }
            });
            onMounted(() => {
                comp.path = "";
                window.addEventListener('resize', comp.handleResize);
                comp.handleResize();
            });
            onBeforeUnmount(() => {
                window.removeEventListener('resize', comp.handleResize);
            });
            return { comp, props, bg };
        },
    }
